


















































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { sortBy } from 'lodash';
import { plainToClass } from 'class-transformer';
import { DefaultExtensions } from '@/utils/tiptap-extensions/default-extensions';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import Field from '@/components/field.vue';
import ContentDialog from '@/components/content-dialog.vue';
import EmailComposer from '@/components/email-composer.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ClientModel from '@/models/crm/client.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import EmailModel from '@/models/email.model';
import ContactService from '@/services/crm/contact.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ProspectModel from '@/models/crm/prospect.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import GroupModel from '@/models/crm/group.model';
import ActivityService from '@/services/crm/activity.service';
import SettingsModel from '@/models/crm/settings.model';
import SettingsService from '@/services/crm/settings.service';

@Component({
  components: {
    Field,
    EmailComposer,
    ContentDialog,
  },
})
export default class CrmEmailView extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  settings: SettingsModel | null = null;

  extensions = DefaultExtensions.getExtensionsNoTable();

  formIsValid = true;

  dialogConfig: IKeyValue<IDialogConfig> = {
    classifyEmail: {
      show: false,
    },
  };

  emailModel: EmailModel = plainToClass(EmailModel, {
    cnpj: '',
    prospect: null,
    from: '',
    to: [],
    cc: [],
    subject: '',
    sendCopy: false,
    message: '',
    attachments: [],
    groupArea: null,
    historyType: null,
  });

  historyTypeOptions: HistoryTypeModel[] = [];

  groupOptions: GroupModel[] = [];

  groupArea: GroupModel | null = null;

  historyType: HistoryTypeModel | null = null;

  loadingHistoryTypeOptions = false;

  rules = {
    from: [[ValidationRules.arrayLength, 1], ValidationRules.arrayOfEmails],
    to: [[ValidationRules.minArrayLength, 1], ValidationRules.arrayOfEmails],
    cc: [ValidationRules.arrayOfEmails],
    subject: [ValidationRules.required],
    message: [ValidationRules.required],
    attachments: [[ValidationRules.fileMaxSize, 10240]], // 10MB
    historyType: [ValidationRules.required],
    groupArea: [ValidationRules.required],
  };

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  @Watch('groupArea', { immediate: true })
  onGroupAreaChanged(value: GroupModel): void {
    if (value) {
      this.rules.historyType = [ValidationRules.required];
      return;
    }
    this.historyTypeOptions = [];
    this.rules.historyType = [];
  }

  async mounted(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
    if (!this.settings?.flagObrigaClassificacaoEmail) {
      this.rules.groupArea = [];
      this.rules.historyType = [];
    }
    if (this.userContactInfo && this.userContactInfo.email) {
      this.emailModel.from = this.userContactInfo.email;
    }
    if (this.client && this.client.email) {
      this.emailModel.to = [this.client.email];
    }

    this.emailModel.subject = this.getDefaultSubject();
    this.emailModel.message = this.getDefaultMessage();

    this.loadGroups();
  }

  onClassifyEmail(): void {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      this.dialogConfig.classifyEmail.show = true;
    }
  }

  private async loadHistoryTypes(groupId: number): Promise<void> {
    this.loadingHistoryTypeOptions = true;
    this.historyTypeOptions = sortBy(await this.activityService.getHistoryTypes(groupId), 'nome');
    this.loadingHistoryTypeOptions = false;
  }

  private async loadGroups(): Promise<void> {
    this.groupOptions = sortBy(await this.activityService.getGroups(), 'descricao');
  }

  onGroupChange(model: GroupModel): void {
    this.loadHistoryTypes(model.id);
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.classifyingForm as Vue)) {
      this.dialogConfig.classifyEmail.show = false;
      const loader = this.$loading.show({
        container: this.$refs.emailViewContainer,
        canCancel: false,
      });
      try {
        if (this.clientType === ClientTypeEnum.Prospect) {
          const prospect = new ProspectModel();
          prospect.codProspect = this.client.codCliente;
          this.emailModel.prospect = prospect;
        }
        this.emailModel.cnpj = this.client.cnpjCpf;
        if (this.groupArea) {
          this.emailModel.groupArea = {
            id: this.groupArea.id,
            descricao: this.groupArea.descricao,
          };
        }
        if (this.historyType) {
          this.emailModel.historyType = {
            id: this.historyType.id,
            descricao: this.historyType.nome,
          };
        }

        await this.contactService.sendEmail(this.emailModel);

        this.$notify.success(this.$t('crm.emailView.successfullySent'));

        this.$emit('complete');
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  private getDefaultSubject(): string {
    return this.userContactInfo.nomeEmpresa;
  }

  private getDefaultMessage(): string {
    let message = '';
    if (this.userContactInfo.assinaturaEmail) {
      message += `<br><br>${this.userContactInfo.assinaturaEmail.replaceAll(/(?:\r\n|\r|\n)/g, '<br>')}`;
    }

    return message;
  }
}
