import { render, staticRenderFns } from "./wysiwyg-editor.vue?vue&type=template&id=0b960e2d&"
import script from "./wysiwyg-editor.vue?vue&type=script&lang=ts&"
export * from "./wysiwyg-editor.vue?vue&type=script&lang=ts&"
import style0 from "./wysiwyg-editor.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VInput } from 'vuetify/lib/components/VInput';
installComponents(component, {VInput})
